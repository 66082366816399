/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ModelAccountState } from '@/store/modelAccount/types'
import { toBase64 } from '@/services/api.upload'
const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountAboutMePage extends Vue {
	@State('modelAccount', {namespace}) modelAccount: ModelAccountState | undefined;
	@Getter('getModelSettingsUsername', {namespace}) getModelSettingsUsername: any;
	@Action('updateProfileInformation', {namespace}) updateProfileInformation: any;

	aboutMe: string = '';
	image: string = '';
	photoValue: string = '';

	$refs!:{
		refProfilePhoto: HTMLInputElement
	}
	async handleFileUpload() {
		const photo = this.$refs.refProfilePhoto.files![0];
		this.photoValue = this.$refs.refProfilePhoto.value;
		return await toBase64(photo).then(data => this.image = data);
	}
	onSubmit() {
		this.updateProfileInformation({
			about: this.aboutMe,
			image: this.image
		})
		this.aboutMe = '';
	}
}
